@import "/src/styles/mixin";

.modal_container {
  padding: 0 0;
}

.wrap {
  width: calc(100vw - 24px);
  max-width: 350px;
  @media only screen and (min-width: 320px) and (max-width: 425px) {
    max-width: calc(100vw - 40px);
  }
}

.subtitle {
  @include font16;
  margin-bottom: 24px;
}

.input {
  padding: 16px 20px 8px;
  border: none;
  width: 400px;
  &Wrap {
    margin-bottom: 24px;
  }
}

.button {
  padding: 14px 26px;
}

.checkbox {
  margin-bottom: 24px;
}
