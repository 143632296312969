@import "/src/styles/mixin";

.text {
  @include font16;
  &Wrap {
    margin-bottom: 32px;
  }
}

.title {
  @include font16Bold;
}

.panel {
  max-width: 800px;
  padding-bottom: 30px;
}

.link {
  @include contentButton;
  @include font14;
  display: inline-block;
  padding: 16px 26px;
}
