@import "/src/styles/mixin";

.store {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;

  &Info {
    width: 100%;
  }
}

.icon {
  width: 20px;
  height: 24px;
}

.address {
  @include font14Bold;
  padding-right: 16px;
  font-style: normal;
  cursor: pointer;

  span {
    display: block;
    font-weight: 500;
    color: var(--grey-color);
  }
}

.quantity {
  padding: 4px 4px;
  background-color: var(--grey-lines-color);
  border-radius: var(--border-radius-small);
  margin: 4px auto 0 0;
  width: fit-content;
}

.phone {
  @include font14;
  font-weight: 700;
  color: var(--grey-color);
}

.shop_link {
  @include font14;
  @include contentButton;
  position: relative;
  display: block;
  transition: all 0.25s ease;
  width: fit-content;
  padding: 6px 12px;
  margin-top: 8px;
}
