@import "/src/styles/mixin";

.modal_container {
  @media screen and (max-width: 425px) {
    max-width: calc(100vw - 50px);
    padding: 0 0;
  }
}

.wrap {
  width: calc(100vw - 40px);
  max-width: 304px;

  .auth_variants {
    background-color: var(--blue-background-color);
    border-radius: var(--border-radius-small);

    .auth_container {
      position: relative;
      display: flex;
      justify-content: space-between;
      gap: 26px;
      padding: 7px 6px;

      .auth_button {
        @include font14;
        z-index: 2;
        padding: 7px 37px;
        width: auto;
        transition: 0.25s;
        opacity: 0.75;
        border-radius: var(--border-radius-small);
      }

      .auth_button:hover {
        background-color: var(--white-color);
      }

      .auth_button_active {
        opacity: 1;
        background-color: var(--white-color);
      }
    }
  }
}
