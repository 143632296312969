@import "/src/styles/mixin";

.item {
  border-top: 1px solid var(--black-color);
  background-color: var(--white-color);

  &Button {
    @include font16Bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    padding: 20px 0;

    &Opened {
      width: 100%;

      .chevron {
        transform: rotate(180deg);
      }
    }

    .chevron {
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
      transition: transform 0.2s ease;
    }
  }

  &Content {
    transition: height 0.2s ease;
  }
}
