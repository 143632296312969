@import "/src/styles/mixin";

.radioButton {
  @include font14;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid var(--grey-lines-color);
}

.wrap {
  padding-left: 20px;
}
