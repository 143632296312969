@import "/src/styles/mixin";

.cart_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .wrap {
    position: relative;
    width: 100%;
    max-width: 639px;
    height: 100%;
    max-height: 800px;
    background-color: var(--white-color);
    border-radius: var(--border-radius-medium);
    z-index: 2;
    @media screen and (max-width: 767px) {
      max-height: 100svh;
      border-radius: 0px;
      max-width: 100%;
    }

    .actions {
      width: 100%;
      max-width: 639px;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      padding: 16px 70px 16px 70px;
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: var(--white-color);
      z-index: 2;
      border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);

      @media screen and (max-width: 767px) {
        bottom: 0;
      }

      .link {
        @include font14Bold;
        padding: 12px 50px;
        white-space: nowrap;
        background-color: var(--blue-background-color);
        border-radius: var(--border-radius-small);

        @media screen and (max-width: 767px) {
          padding: 12px 23px;
        }
      }
    }

    .container {
      position: relative;
      height: calc(100% - 80px);
      width: auto;
      padding: 54px 54px 0 48px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 767px) {
        padding: 0 17px 0 17px;
        max-height: 100svh;
      }

      .close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          top: 8px;
          right: -8px;
        }
      }

      .card_infoAndTabsWrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        width: 100%;

        @media screen and (max-width: 767px) {
          max-width: 445px;
          margin: 0 auto;
        }
      }

      .card_info {
        display: flex;
        flex-direction: column;

        .card_photo {
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 767px) {
            flex-flow: column-reverse;
          }

          .article {
            @include font14;
            color: var(--grey-fourth);

            @media screen and (max-width: 767px) {
              display: none;
            }
          }
        }
      }

      .head {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 30px;
        margin-bottom: 18px;

        @media screen and (max-width: 767px) {
          position: sticky;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          padding: 8px 0;
          background-color: var(--white-color);
          margin-bottom: 0px;
          z-index: 3;
        }

        .title {
          display: flex;
          flex-direction: column;

          h2 {
            @include font20;
            font-weight: 800;

            @media screen and (max-width: 767px) {
              width: calc(100% - 28px);
              @include font14;
              font-weight: 700;
            }
          }

          .article {
            @include font14;
            color: var(--grey-fourth);

            @media screen and (max-width: 767px) {
              display: flex;
              flex-direction: column;
            }
          }

          .rating {
            @include font14;
            font-weight: 600;
            color: var(--grey-fourth);
            white-space: nowrap;

            &_stars {
              color: var(--primary-color);
              font-size: 16px;
            }

            &_number {
              @include font14;
              font-weight: 700;
              color: var(--secondary-color);
            }
          }
        }
      }
    }
  }
}

.price_wrap {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  background-color: #e9e9e9;
  border-radius: var(--border-radius-small);
  padding: 8px;
  width: fit-content;

  .price {
    @include font14;
    line-height: 18px;
    color: var(--grey-fourth);
    text-decoration: line-through;
  }

  .discount {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 8px;

    .price_discount {
      @include font20;
    }

    .price_percent {
      @include font14Bold;
      color: var(--red-color);
    }
  }
}

.price_head {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.price_info {
  flex-flow: column-reverse;

  .discount {
    .price_percent {
      display: none;
    }
  }

  @media screen and (min-width: 767px) {
    display: none;
  }
}
