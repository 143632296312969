@import "/src/styles/mixin";

.review {
  &Row {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &Text {
    @include font14;
    display: flex;
    flex-flow: row;
    margin-right: 10px;
    width: 65px;
  }
  &Percent {
    position: relative;
    margin-left: auto;
    width: 70%;
    height: 5px;
    border-radius: 5px;
    background-color: var(--blue-background-color);
    &Line {
      position: absolute;
      left: 0;
      height: 100%;
      background-color: var(--primary-color);
      border-radius: 2px;
    }
  }
  &Count {
    @include font14;
    margin-left: 10px;
    text-align: end;
  }
}
