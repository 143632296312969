@import "/src/styles/mixin";

.wrap {
  width: 345px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;
  @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
    width: 315px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024.98px) {
    width: 278px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: 100%;
  }
}

.link {
  @include font14;
  padding: 16px;
  background-color: var(--blue-background-color);
  border-radius: var(--border-radius-small);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  transform: rotate(180deg);
}
