.close {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 7px;

  .icon {
    width: 10px;
    height: 10px;
  }

  &::after {
    position: absolute;
    content: "";
    top: -10px;
    right: -10px;
    width: 50px;
    height: 50px;
  }
}
