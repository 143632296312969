@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  position: relative;

  @media screen and (max-width: 425px) {
    row-gap: 12px;
  }

  .head {
    display: flex;
    flex-direction: column;

    .info {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      column-gap: 20px;

      .userName {
        @include font16Bold;
      }

      .date {
        @include font14;
        color: var(--grey-fourth);
      }
    }

    .question {
      @include font18;
    }

    .answer {
      @include dashedBottomLine;
      @include font14;
      display: flex;
      width: fit-content;
      color: var(--grey-third);

      &::before {
        border-color: var(--grey-third);
      }
    }
  }

  .answer_list {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding-left: 40px;

    @media screen and (max-width: 425px) {
      padding-left: 32px;
    }

    .show_more {
      width: fit-content;
      @include dashedBottomLine;

      &::before {
        bottom: -4px;
        border-color: var(--black-color);
      }
    }
  }

  .start_answer {
    max-height: 0px;
    height: auto;
    overflow: hidden;
    transition: all 0.25s ease;

    .textarea {
      border: none;
      width: 100%;
      max-width: 802px;
      min-width: 100%;
      min-height: 142px;
      height: auto;
      max-height: 300px;
      padding: 12px 16px;
      margin-bottom: 16px;
      border: 1px solid var(--grey-lines-color);
      border-radius: var(--border-radius-small);
      resize: none;
    }

    .textarea:focus {
      outline: none;
    }
    .send {
      @include contentButtonTransparent;
      width: fit-content;
      padding: 14px 50px;
    }
  }

  .active {
    max-height: 300px;
    transition: 0.25s;
  }
  .hidden {
    max-height: 0px;
  }
}
