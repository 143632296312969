@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius-normal);
  background-color: var(--white-color);

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    padding: 24px 12px;
  }
}

.price {
  @include font18;
  text-decoration: line-through;

  &Discount {
    @include font30;
  }

  &Wrap {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}

.text {
  @include font14;

  &Label {
    @include font12;
    font-style: italic;
    font-weight: 500;
  }
}

.link {
  @include font14;
  text-decoration: underline;
  text-underline-offset: 6px;
}

.isNotAvailable {
  @include font14;
  position: relative;
  padding-left: 12px;

  &::before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--red-color);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
