@import "/src/styles/mixin";

.title {
  @include font16Bold;
  margin-bottom: 12px;
  text-align: center;
}

.infoText {
  @include font14;
  margin-bottom: 12px;
  color: var(--grey-color);
  text-align: center;

  span {
    font-weight: 800;
  }
}

.input {
  margin-bottom: 24px;
}

.button,
.buttonDisabled {
  @include font14;
  padding: 4px 12px;
  display: block;
  margin: 0 auto;
  width: 208px;
}

.buttonDisabled {
  border-color: var(--blue-background-color);
  background-color: var(--blue-background-color);
  pointer-events: none;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .buttonBack {
    margin: 4px 0;
    opacity: 0.55;
    cursor: pointer;
    transition: 0.25s;
    @include font14;
    font-weight: 300;
  }

  .buttonBack:hover {
    opacity: 1;
  }
}

.expample_wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px 16px 12px;
  box-shadow: 0px 0px 8px 0px #00000026;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-small);
  width: fit-content;
  margin: 0 auto 24px;

  .text {
    @include font12;
    font-weight: 500;
    line-height: 20px;
  }

  .code_example {
    @include font18;
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 4px;
    background-color: var(--grey-secondary);
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    font-weight: 500;
    width: 176px;

    .dots {
      display: flex;
      flex-direction: row;
      gap: 4px;

      .dot {
        width: 4px;
        height: 4px;
        background-color: var(--secondary-color);
        border-radius: 50%;
      }
    }

    .icon {
      width: 24px;
      height: 24px;
    }

    .code {
      padding: 4px 6px;
      color: var(--white-color);
      background-color: var(--red-danger-color);
      border-radius: 4px;
      letter-spacing: 0.2em;
    }
  }

  .email {
    width: 123px;
    max-width: 123px;
  }
}
