@import "/src/styles/mixin";

.button,
.notAvailableButton,
.inCartButton {
  @include font14;
  padding: 14px 20px;
  width: 100%;
}
.notAvailableButton {
  border: 1px solid var(--blue-background-color);
  background-color: var(--blue-background-color);
  pointer-events: none;
}
.inCartButton {
  border: 1px solid var(--grey-lines-color);
  background-color: transparent;
  pointer-events: none;
}
