@import "/src/styles/mixin";

.answer_item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 24px;
  border-left: 1px solid var(--grey-fourth);

  @media screen and (max-width: 425px) {
    padding-left: 16px;
  }

  .best_answer {
    width: fit-content;
    border-radius: var(--border-radius-small);
    display: flex;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;
    background-color: var(--green-secondary);

    @include font14Bold;
    color: var(--white-color);

    svg {
      width: 16px;
      height: auto;

      path {
        stroke: var(--white-color);
        fill: var(--green-secondary);
      }
    }
  }

  .answer_header {
    display: flex;
    flex-wrap: wrap;

    .answer_name {
      @include font14Bold;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .answer_date {
      @include font14;
      color: var(--grey-fourth);
      margin-left: 12px;
    }
  }

  .answer_text {
    @include font14;

    a {
      font-weight: 700;
      color: var(--black-color);
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  .answer_actions {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 16px;

    p {
      @include font14;
      color: var(--grey-fourth);
    }

    .action {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 3px;
      color: var(--grey-fourth);
      touch-action: manipulation;

      svg {
        width: 16px;
        height: auto;

        path {
          fill: transparent;
          stroke: var(--grey-fourth);
        }
      }
    }

    .like_active {
      svg {
        path {
          stroke: var(--green-secondary);
          fill: var(--green-secondary);
        }
      }
    }

    @keyframes like {
      0% {
        transform: scale(1);
      }

      25% {
        transform: rotate(-45deg) scale(1.52);
      }

      50% {
        transform: rotate(0deg) scale(1.52);
      }

      75% {
        transform: rotate(-45deg) scale(1.52);
      }

      100% {
        transform: scale(1);
      }
    }

    @keyframes likeIcon {
      to {
        fill: var(--green-color);
      }

      from {
        fill: var(--green-secondary);
      }
    }

    .dislike_active {
      svg {
        path {
          fill: var(--red-color);
        }
      }
    }

    @keyframes dislike {
      0% {
        transform: scale(1);
      }

      50% {
        transform: rotate(45deg) scale(1.52);
      }

      100% {
        transform: scale(1);
      }
    }
  }
}

.styles.best {
  border-left: 1px solid var(--green-secondary);
}
