@import "/src/styles/mixin";

.title {
  @include font16Bold;
  margin-bottom: 12px;
  text-align: center;
}

.infoText {
  @include font14;
  margin-bottom: 12px;
  color: var(--grey-color);
  span {
    font-weight: 800;
  }
}

.input {
  margin-bottom: 24px;
}

.button {
  @include font14;
  padding: 14px 26px;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonBack {
  margin: 4px 0;
  opacity: 0.55;
  cursor: pointer;
  transition: 0.25s;
  @include font14;
  font-weight: 300;
}
.buttonBack:hover {
  opacity: 1;
}
