@import "/src/styles/mixin";

.wrap {
  width: 345px;
  height: fit-content;
  padding: 24px;
  border-radius: var(--border-radius-normal);
  background-color: var(--white-color);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    padding: 24px 12px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
    width: 315px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024.98px) {
    width: 278px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: 100%;
  }
}

.title {
  @include font16Bold;
  &Wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.stars {
  display: flex;
  align-items: center;
  gap: 2px;
}

.starsby {
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
