@import "/src/styles/mixin";

.modalHeader {
  position: relative;
  display: flex;
  gap: 16px;
  text-align: center;
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;
}

.title {
  @include font20;
  text-align: center;
  @media only screen and (min-width: 768px) and (max-width: 1920px) {
    margin-bottom: 24px;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 12px;
  }
}
