@import "src/styles/mixin";

.store_wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  padding: 12px 0 12px;

  .icon {
    width: 20px;
    height: 24px;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .name {
      @include font16;
      color: var(--secondary-color);
      line-height: 22px;
      font-weight: 700;
    }

    .schedule {
      @include font12;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-align: left;
      color: var(--grey-third);
    }

    .quantity {
      @include font14;
      letter-spacing: 0.01em;
      padding: 4px 8px;
      background-color: var(--blue-background-color);
      border-radius: var(--border-radius-small);
      margin-right: auto;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px dashed var(--grey-lines-color);
  }
}
