@import "/src/styles/mixin";

.radioInput {
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  &:checked + label,
  &:not(:checked) + label {
    @include font14;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    display: inline-block;
    color: var(--black-color);
  }
  &:checked + label:before,
  &:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 16px;
    height: 16px;
    border: 1px solid var(--secondary-color);
    border-radius: 100%;
    transform: translate(0, -50%);
  }
  &:checked + label:after,
  &:not(:checked) + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: var(--secondary-color);
    position: absolute;
    top: 50%;
    left: 4px;
    border-radius: 100%;
    transition: opacity 0.2s ease;
  }
  &:not(:checked) + label::after {
    opacity: 0;
    transform: scale(0) translate(0, -50%);
  }
  &:checked + label::after {
    opacity: 1;
    transform: scale(1) translate(0, -50%);
  }
  &Reverse {
    &:checked + label,
    &:not(:checked) + label {
      padding-left: 0;
      padding-right: 30px;
    }
    &:checked + label:before,
    &:not(:checked) + label:before {
      left: unset;
      right: 0;
    }
    &:checked + label:after,
    &:not(:checked) + label:after {
      right: 4px;
      left: unset;
    }
  }
}
