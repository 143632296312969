@import "src/styles/mixin";

.tabs {
  width: 100%;
  max-width: 288px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 8px;
  background-color: var(--blue-background-color);
  border-radius: var(--border-radius-small);

  &_button {
    padding: 4px 12px;
    width: 100%;
    max-width: 131px;
    border-radius: var(--border-radius-small);
    border: 1px solid transparent;
    transition: all 0.25s ease-in-out;
    @include font16Bold;
  }

  .active {
    border-color: var(--primary-color);
    background-color: var(--white-color);
  }
}
