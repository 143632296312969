@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background-color: var(--white-color);
}

.title {
  @include font18;
}

.size {
  &Wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 8px;
  }

  &Available,
  &NotAvailable,
  &IsActive {
    @include font14;
    padding: 11px 15px;
    border: 1px solid var(--grey-lines-color);
    border-radius: 5px;
    transition: border 0.2s ease;
  }

  &IsActive {
    border-color: var(--primary-color);
  }

  &Available {
    &:hover {
      border-color: var(--grey-color);
    }
  }

  &NotAvailable {
    border-color: var(--blue-background-color);
    background-color: var(--blue-background-color);
    color: var(--grey-lines-color);
    cursor: default;
    pointer-events: none;
  }
}

.button {
  @include font14;
  padding: 8px;
  border-radius: var(--border-radius-small);
  background-color: var(--blue-background-color);
}
