@import "/src/styles/mixin";

.modalContainer {
  padding: 20px 20px;

  .wrap {
    width: 100%;
    max-width: 330px;
    min-width: 288px;
    text-align: center;
    align-items: center;
    justify-content: center;

    .callback_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      max-width: 330px;

      .popup_wrap__subtitle {
        @include font14;
        margin-bottom: 6px;
      }

      .input_wrap {
        width: 100%;
        display: flex;
        gap: 24px;
        flex-direction: column;
      }
    }

    .popup_wrap__button {
      @include font14;
      max-width: 164px;
      padding: 14px 26px;
      background-color: var(--primary-color);
      border-radius: var(--border-radius-small);
      transition: 0.15s;
      margin-top: 24px;

      &:hover {
        background-color: var(--hover-primary-color);
      }
    }

    .info_text {
      margin-top: 24px;
      @include font14;
      font-weight: 400;
      color: var(--grey-color);

      a {
        color: var(--primary-color);
      }
    }

    .enter_or {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 14px;
      @include font14;
      font-weight: 200;
      color: var();

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: var(--grey-lines-color);
      }

      &::before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: var(--grey-lines-color);
      }
    }

    .or__wrap {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .or__text {
        @include font14;
        color: var(--grey-color);
      }

      .tel {
        display: flex;
        width: fit-content;
        @include font14;
        font-weight: 600;
        color: var(--secondary-color);
        background-color: var(--blue-background-color);
        padding: 4px 8px;
        border-radius: var(--border-radius-small);
        margin-top: 8px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
