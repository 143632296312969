@import "/src/styles/mixin";

.header {
  max-width: 800px;
  padding: 30px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 0;
  }
}

.name {
  @include font24;
  margin-bottom: 12px;
}

.price {
  @include font18;
  margin-bottom: 24px;
  padding: 2px 12px;
  border-radius: var(--border-radius-small);
  background-color: var(--primary-color);
}

.address {
  @include font16Bold;
  margin-bottom: 20px;
  font-style: normal;
}

.job {
  @include font16;
  text-align: left;
  span {
    display: block;
    font-weight: 800;
  }
}
