@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  cursor: default;
  width: 300px;

  .search {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 4px;
    background-color: var(--blue-background-color);
    border-radius: var(--border-radius-small);
    overflow: hidden;

    .input {
      width: 100%;
    }

    & > button {
      padding: 4px 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 12px;
        height: 12px;
        margin-right: 6px;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    background-color: var(--blue-background-color);
    border-radius: var(--border-radius-small);
    margin-top: 6px;
    max-height: 400px;
    overflow-x: auto;

    .elem {
      display: flex;
      flex-direction: column;
      padding: 10px 6px;
      color: var(--secondary-color);
      background-color: var(--blue-background-color);
      transition: all 0.25s ease-in-out;
      cursor: pointer;
      max-width: 300px;

      .name {
        @include font14Bold;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .desc {
        margin: 4px 0 0;
        @include font14;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &:first-child {
        padding-top: 16px;
      }

      &:last-child {
        padding-bottom: 16px;
      }

      &:hover {
        background-color: var(--grey-light-color);
      }
    }
  }
}
