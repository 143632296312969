.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black-color);
  z-index: 50;

  backdrop-filter: blur(20px);
}

.high {
  z-index: 899999;
  background-color: rgba(0, 0, 0, 0.85);
}
