.modal {
  max-height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 32px 48px;
  transform: translate(-50%, -50%);
  box-shadow:
    0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: var(--border-radius-normal);
  background-color: var(--white-color);
  overflow: auto;
  z-index: 50;

  @media screen and (max-width: 768px) {
    padding: 20px 20px;
  }

  &_container {
    position: relative;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
      width: auto;
      //padding: 40px 30px;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }
  }

  &::-webkit-scrollbar {
    width: 3px;
  }
}
