@import "/src/styles/mixin";

.banner {
  height: 100%;
  object-fit: cover;
  &Wrap {
    position: relative;
  }
  &Title {
    @include font35;
    margin-bottom: 40px;
    max-width: 700px;
    color: var(--white-color);
    text-align: center;
    &Wrap {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media only screen and (min-width: 320px) and (max-width: 767.98px) {
        width: 288px;
      }
    }
    @media only screen and (min-width: 1440px) and (max-width: 1559.98px) {
      margin-bottom: 32px;
    }
    @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
      margin-bottom: 24px;
    }
  }
  &Link {
    @include contentButton;
    @include font14;
    text-align: center;
    padding: 16px 26px;
    border-radius: var(--border-radius-small);
  }
  @media only screen and (min-width: 1440px) and (max-width: 1559.98px) {
    height: 480px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1365.98px) {
    height: 390px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    height: 470px;
  }
}

.why {
  &List {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 200px));
    justify-content: center;
    gap: 110px;
    @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
      gap: 80px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
      gap: 30px;
      grid-template-columns: repeat(auto-fit, minmax(150px, 180px));
    }
  }
  &Wrap {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      display: none;
    }
  }
}

.subtitle {
  @include font24;
  margin-bottom: 100px;
}
